<template>
  <v-container class="container-full-size">
    <div>
      <calendar :client="client"></calendar>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ClientCalendar',
  components: {
    Calendar: () => import('@/components/dashboard/calendar/Calendar'),
  },
  props: {
    client: Object,
  },
  data() {
    return {
      str: window.strings,
    }
  },
}
</script>
